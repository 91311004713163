<template>
  <div className="page-mengjing">
    <top-cover :option="{title:' ',cover: $hwcdnLink('imagehost/vrhome_topcover/mengjing.jpg')}" />

    <toc :items="tocItems" />

    <main>
      <section v-for="(A,a) in tocItems" :key="a">

        <!-- vr社交 -->
        <section class="section a" id="glasses" v-if="a===0">
          <h2 :id="A.el">{{A.label}}</h2>
          <div class="block a">
            <!-- <div class="word a" v-bind="ani.title_a_x">
              <h2 :id="A.el">{{A.label}}</h2>
              <p class="top-intro">梦境VR内容云平台采用全球领先的云VR技术，为您提供海量优质VR游戏内容和极致的云游戏体验，<br>无需下载安装，随时随地即开即玩，畅快体验游戏。</p>
            </div> -->
            <div class="word b" v-bind="ani.title_a_x">
              <h2 :id="A.el">梦境是与现实世界平行的另一个虚拟世界<br>戴上VR眼镜</h2>
              <p class="top-intro">从现实到虚拟世界，来到的是一个全新的国度，<br>有非常多喜爱VR的朋友在这里，这里是有趣的灵魂聚集地，<br>我们一起在这里聊天，游戏，做现实难以做到的事，<br>时刻感受虚拟世界带来的震撼！</p>
            </div>

            <!-- 背景图 -->
            <div class="background" v-bind="ani.bg_box">
              <img class="img-bg" :src="require('@/assets/mengjing/game_clip.jpg')">
              <div class="word" v-bind="ani.bg_word">
                <p class="intro">“我”和“你”在另一个世界相遇<br>无论远在千里，还是近在咫尺，“我们”在这个虚拟世界相遇！</p>
                <p class="intro">“我”和“你”面对面交流<br>在幻想的虚拟世界里，“我们”围在一起谈天说地，有说有笑~ </p>
                <p class="intro">“我”和“你”一起玩游戏<br>“我们”一起上九天揽月，下五洋捉鳖，做那些现实世界做不了的大事！</p>
                <p class="intro">“我”和“你”形象自定义<br>专属角色，百变造型，“我们”重新定义自己，秀出自我！</p>
              </div>
            </div>
            <!-- 眼镜 -->
            <div class="glasses" v-bind="ani.glasses">
              <img class="img-glasses" :src="require('@/assets/mengjing/vrglasses.png')">
              <div class="light" v-bind="ani.glasses_light"></div>
            </div>
          </div>
        </section>

        <!-- 内容平台 -->
        <section class="section b" v-if="a===1">
          <div class="title-box">
            <h2 :id="A.el">{{A.label}}</h2>
          </div>
          <div class="block b" id="handshank">
            <img class="img-handshank" :src="require('@/assets/mengjing/handshank.png')" alt="海量精品VR内容" v-bind="ani.handshank">
            <section class="context a">
              <h3>梦境云内容平台</h3>
              <p>梦境VR内容云平台采用全球领先的云VR技术，为您提供海量优质VR游戏内容和极致的云游戏体验，<br>无需下载安装，随时随地即开即玩，畅快体验游戏。</p>
            </section>
            <section class="context b">
              <h3>数千款游戏和体验</h3>
              <p>体验各种类型游戏的刺激和激情<br>游戏包括动作、运动、冒险、恐怖、儿童等</p>
            </section>
          </div>

          <div class="block c">
            <div class="game-item" v-for="(B,b) in 22" :key="$randomKey(b)">
              <img class="img-game" :src="$hwcdnLink(`imagehost/vrhome_mengjing_games/${B}.jpg`)">
            </div>
          </div>

          <div class="block d">
            <p v-for="(B,b) in sectionB.blockD" v-html="B" :key="$randomKey(b)"></p>
          </div>
        </section>
      </section>
    </main>

    <partner />
    <page-footer class="footer" />
  </div>
</template>

<script>
import topCover from "@/components/TopCover";
import pageFooter from "@/components/Footer";
import partner from "@/components/Partner";
import toc from "@/components/Toc";

import skrollr from "skrollr";

export default {
  components: {
    topCover,
    pageFooter,
    partner,
    toc,
  },
  data() {
    return {
      tocItems: [
        {
          icon: "icon-VRshejiao",
          label: "梦境VR社交",
          el: "toc_social",
        },
        {
          icon: "icon-VR1",
          label: "梦境云内容平台",
          el: "toc_mjcontent",
        },
      ],

      sectionA: {
        gifs: [
          {
            title: "① 定制“形象”&“造型”",
            content: [
              {
                text: "百变穿搭造型，可男可女可萝莉！",
                img: this.$hwcdnLink('imagehost/vrhome_mengjing_gifs/1.gif'),
              },
            ],
          },
          {
            title: "② VR交友畅快“音聊”",
            content: [
              {
                text:
                  "眼前一壮汉，开口御姐音<br>可爱小萝莉，粗犷暴脾气<br>帅哥和美女，反转更有趣",
                img: this.$hwcdnLink('imagehost/vrhome_mengjing_gifs/2.gif'),
              },
            ],
          },
          {
            title: "③ 互动小游戏，玩乐趣无限",
            content: [
              {
                text: "网友变“羊羊”，万物皆可变",
                img: this.$hwcdnLink('imagehost/vrhome_mengjing_gifs/3.gif'),
              },
              {
                text:
                  "“你怎么不上天，和太阳肩并肩？”<br>以前我没办法上天，现在我可以了~",
                img: this.$hwcdnLink('imagehost/vrhome_mengjing_gifs/4.gif'),
              },
            ],
          },
        ],
      },
      sectionB: {
        blockD: [
          "海量精品VR内容",
          "让每一次游戏都充满新鲜感",
          "下载梦境VR App，解锁更多精彩内容...",
          "全球领先云VR技术<br>所有游戏均在云端运行，无需下载游戏安装包<br>随时随地即开即玩",
          "小眼镜玩大游戏<br>使用VR Glass即可畅玩PC级VR大作，享受硬核内容的顶级体验",
        ],
      },

      ani: {
        bg_box: {},
        bg_word: {},
        glasses: {},
        glasses_light: {},
        title_a: {},
        title_b: {},
        handshank: {},
      },
    };
  },
  mounted() {
    this.initAni().then(() => {
      skrollr.init();
    });
  },
  beforeDestroy() {
    skrollr.init().destroy();
  },
  methods: {
    initAni() {
      return new Promise((R) => {
        // const elTop = document.querySelector('.section.b .block.a').getBoundingClientRect().top + window.scrollY
        const el_glasses_top = document.querySelector("#glasses").offsetTop,
          el_handshank_top = document.querySelector("#handshank").offsetTop;

        console.log(el_glasses_top, el_handshank_top);

        let _title_a = {};
        const title_a_key_0 = "data-" + (el_glasses_top + 50),
          title_a_key_1 = "data-" + (el_glasses_top + 100);
        _title_a[title_a_key_0] = "opacity:1";
        _title_a[title_a_key_1] = "opacity:0";
        this.ani.title_a = _title_a;

        let _title_b = {};
        const title_b_key_0 = "data-" + (el_glasses_top + 150),
          title_b_key_1 = "data-" + (el_glasses_top + 200);
        _title_b[title_b_key_0] = "opacity:0";
        _title_b[title_b_key_1] = "opacity:1";
        this.ani.title_b = _title_b;

        let _bg_box = {};
        const bg_box_key_0 = "data-" + (el_glasses_top - 160),
          bg_box_key_1 = "data-" + el_glasses_top,
          bg_box_key_2 = "data-" + (el_glasses_top + 440),
          bg_box_key_3 = "data-" + (el_glasses_top + 800),
          bg_box_key_4 = "data-" + (el_glasses_top + 1139),
          bg_box_key_5 = "data-" + (el_glasses_top + 1140);
        _bg_box[bg_box_key_0] = "position:absolute; top:0px;";
        _bg_box[bg_box_key_1] =
          "position:fixed; top:20px; transform:scale(0.5);";
        _bg_box[bg_box_key_2] = "transform:scale(0.7);";
        _bg_box[bg_box_key_3] = "transform:scale(1);";
        _bg_box[bg_box_key_4] = "top:0;";
        _bg_box[bg_box_key_5] = "position:absolute; top:1145px;";
        this.ani.bg_box = _bg_box;

        let _bg_word = {};
        const bg_word_key_0 = "data-" + (el_glasses_top + 1140 - 50),
          bg_word_key_1 = "data-" + (el_glasses_top + 1140);
        _bg_word[bg_word_key_0] = "top:50px; opacity:0";
        _bg_word[bg_word_key_1] = "top:0; opacity:1";
        this.ani.bg_word = _bg_word;

        let _glasses = {};
        const glasses_key_0 = "data-" + (el_glasses_top - 160),
          glasses_key_1 = "data-" + el_glasses_top,
          glasses_key_2 = "data-" + (el_glasses_top + 740),
          glasses_key_3 = "data-" + (el_glasses_top + 1140);
        _glasses[glasses_key_0] =
          "position:absolute; top:0px; transform:scale(1);";
        _glasses[glasses_key_1] =
          "position:fixed; top:20px; transform:scale(1);";
        _glasses[glasses_key_2] = "transform:scale(2.5); opacity:1;";
        _glasses[glasses_key_3] = "opacity:0; position:absolute;";
        this.ani.glasses = _glasses;

        let _glasses_light = {};
        const glasses_light_key_0 = "data-" + (el_glasses_top - 300),
          glasses_light_key_1 = "data-" + (el_glasses_top - 150),
          glasses_light_key_2 = "data-" + (el_glasses_top - 10);
        _glasses_light[glasses_light_key_0] = "opacity:0";
        _glasses_light[glasses_light_key_1] = "opacity:1";
        _glasses_light[glasses_light_key_2] = "opacity:0";
        this.ani.glasses_light = _glasses_light;

        let _handshank = {};
        const handshank_key_0 = "data-" + (el_glasses_top + 2445 - 500),
          handshank_key_1 = "data-" + (el_glasses_top + 2445 - 100);
        _handshank[handshank_key_0] = "transform: translateY(-200%);";
        _handshank[handshank_key_1] = "transform: translateY(0);";
        this.ani.handshank = _handshank;

        R();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  width: 100%;
  padding-top: 24px;
}

.section.a {
  margin-top: 130px;
  position: relative;

  h2 {
    text-align: center;
    line-height: 1;
    font-size: 30px;
    color: #1c1e20;
    font-weight: lighter;
    margin-bottom: 80px;
  }

  .block.a {
    width: 100%;
    // height: 2440px + 250px;
    height: 2445px;
    background-color: #f7f7f7;
    position: relative;
    overflow: hidden;

    & > .word {
      width: 100%;
      z-index: 99;
      position: absolute;
      h2 {
        margin: 0;
        padding-top: 50px;
        margin-bottom: 50px;
        line-height: 2;
      }
      .top-intro {
        font-size: 18px;
        color: rgba(#323436, 0.8);
        line-height: 48px;
        text-align: center;
      }
    }

    .background {
      width: 100%;
      height: 1300px;
      position: absolute;
      top: 0;
      .img-bg {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        transform: translateX(-50%);
      }
      .word {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        padding-top: 250px;

        .intro {
          font-size: 20px;
          color: #fff;
          line-height: 36px;
          text-align: center;
          margin: 0 0 50px;
          letter-spacing: 5px;
          position: relative;
        }
      }
    }
    .glasses {
      width: 100%;
      height: 1300px;
      position: absolute;
      top: 0;
      transform-origin: 50% 70%;
      .img-glasses {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        transform: translateX(-50%);
      }
      .light {
        width: 888px;
        height: 522px;
        position: absolute;
        display: flex;
        left: 50%;
        top: 467px;
        transform: translateX(-50%);
        background-image: url("~@/assets/mengjing/vrglasses_l.png"),
          url("~@/assets/mengjing/vrglasses_r.png");
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: left top, right top;
      }
    }
  }
}

.section.b {
  .title-box {
    overflow: hidden;
    height: 0;
    opacity: 0;
  }
  .block.b {
    width: 100%;
    // height: 817px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    box-sizing: border-box;
    overflow: hidden;

    .img-handshank {
      width: 612px;
      height: 416px;
      display: block;
      transform: translateY(-200%);
    }
    .context {
      line-height: 1;
      &.a {
        h3 {
          font-size: 48px;
          text-align: center;
          font-weight: lighter;
          margin-bottom: 80px;
          color: #1c1e20;
        }
        p {
          font-size: 18px;
          line-height: 48px;
          text-align: center;
          padding: 0;
          margin: 0;
          color: rgba(#323436, 0.8);
        }
      }
      &.b {
        margin-top: 146px;
        margin-bottom: 124px;
        h3 {
          font-size: 60px;
          text-align: center;
          font-weight: lighter;
          margin-bottom: 120px;
          color: #323436;
        }
        p {
          font-size: 18px;
          line-height: 30px;
          text-align: center;
          padding: 0;
          margin: 0;
          color: #323436;
          position: relative;

          &::before {
            content: "";
            width: 60px;
            height: 16px;
            border-radius: 16px;
            background-color: #323436;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, -60px);
          }
        }
      }
    }
  }

  .block.c {
    width: 100%;
    height: 394px;
    display: flex;

    .game-item {
      height: 100%;
      flex: 1;
      height: 100%;
      transition: flex 0.3s;
      .img-game {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
      &:hover {
        flex: 30;
      }
    }
  }

  .block.d {
    margin-top: 100px;
    font-size: 18px;
    color: #323436;
    text-align: center;
    line-height: 48px;
  }
}
</style>